import React, { useState, useEffect } from "react";
import { TablePagination, Grid, FormControl, TextField, Box, Card, Button, Autocomplete, TableHead, TableCell, TableBody, Table, TableRow, Stack, Checkbox, Typography, Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText, styled } from "@mui/material";
import { Lock, LockOpen, Save, Print, Backspace, Close } from "@mui/icons-material";
import axios from "axios";
import Options from "../components/Options";
import pdfDetalleContratistaResumen from "./pdfs/PdfDetalleContratistaResumen";
import pdfDetalleContratistaDetalle from "./pdfs/PdfDetalleContratistaDetalle";
import pdfDetalleContratistaResumenOC from "./pdfs/PdfDetalleContratistaResumenOC";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function DetalleContratista() {
  /* VARIABLES */
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [contratista, setContratista] = useState(null);
  const [centroCosto, setCentroCosto] = useState(null);
  const [labor, setLabor] = useState(null);
  const [value, setValue] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unidad, setUnidad] = useState(null);
  const [jornada, setJornada] = useState("");
  const [contratistas, setContratistas] = useState([]);
  const [centrosCosto, setCentrosCosto] = useState([]);
  const [labores, setLabores] = useState([]);
  const [detalles, setDetalles] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [dataLocked, setDataLocked] = useState(false);
  const [trato, setTrato] = useState(true);
  const [openOpcionImpresion, setOpenOpcionImpresion] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 25;
  let total = null;
  const [editMode, setEditMode] = useState(false);
  const [infoMode, setInfoMode] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  /* FETCH DATA FROM SERVER */

  function fetchDetalles() {
    const params = {contratista: contratista, centroCosto: centroCosto , labor: labor, mes: month, ano: year};
		
    if (contratista !== null) {
      axios.get('/contratistas/detalle/filtrado', {params: params})
      .then((res)=>{
        if (res.data) {
          setDetalles(res.data);
        }
      })
      .catch((err)=>console.log(err));
    }
  }

  useEffect(()=> {
    fetchDetalles();
  }, [contratista, centroCosto, labor, month, year]);

  useEffect(()=> {
    isError();
  }, [contratista, centroCosto, labor, month, year, day]);


  useEffect(() => {
    axios.get("/contratistas").then((res)=> {setContratistas(res.data)});
    axios.get("/centros-costo").then((res)=> {setCentrosCosto(res.data)});
    axios.get("/labores").then((res)=> {setLabores(res.data)});
    axios.get('/unidades/nombres').then((res)=>setUnidades(res.data));
  }, []);

  /* HANDLES */
  function handleDataLock() {
    setDataLocked(!dataLocked);
  }

  function handleChangePage(e, newPage) {
    setPage(newPage);
  }

  function handlePrint(opcionPrint) {
    if (contratista === null) {
      alert("Ningun contratista seleccionado")
    } else {
      switch (opcionPrint) {
        case 1:
          pdfMake.createPdf(pdfDetalleContratistaResumen(detalles.filter(filterDetalleContratista), detalles, centrosCosto)).open();
          break;
        case 2:
          pdfMake.createPdf(pdfDetalleContratistaDetalle(detalles.filter(filterDetalleContratista), detalles, centrosCosto)).open();
          break;
        case 3:
          pdfMake.createPdf(pdfDetalleContratistaResumenOC(detalles.filter(filterDetalleContratista), detalles, centrosCosto)).open();
          break;
        default:
          break;
      }
    }
  }

  function closeInfoMode() {
    setInfoMode(false);
    resetData();
  }

  function closeOpcionImpresion(value) {
    setOpenOpcionImpresion(false);
    handlePrint(value);
  }

  function handleOpenOpcionImpresion() {
    setOpenOpcionImpresion(true);
  }


  /* SORT DETALLES */
  function sortDetalles(a, b){
    if (a.labor.codigo === b.labor.codigo) {
      return a.dia - b.dia;
    }
    return a.labor.codigo - b.labor.codigo;
  }


  /* FILTERS */

  function filterDetalleContratista(detalle) {
    if (contratista === null) {
      return false;
    }
    if (contratista._id === detalle.contratista._id) {
      if (labor === null || labor._id === detalle.labor._id) {
        if (centroCosto === null || centroCosto._id === detalle.centroCosto._id) {
          if (month === "" || parseInt(month) === detalle.mes) {
            if (year === "" || parseInt(year) === detalle.ano) {
              return true;
            }
          }
        }
      }
    }
  }

  /* LOGIC */

  function calculateTotal() {
    if (trato) {
      total = Math.round(quantity * value);
    } else {
      total = Math.round(jornada * value);
    }
  }

  /* RESETTING DATA */
  function resetData() {
    if (!dataLocked) {
      setMonth("");
      setYear("");
      setContratista(null);
      setCentroCosto(null);
      setLabor(null);
      setUnidad(null);
      setValue("");
    }
    setDay("");
    setQuantity("");
    setJornada("");
    total = "";
  }

  /* DATA VALIDATION */

  function isError() {
    if (contratista !== null && centroCosto !== null && labor !== null && month !== "" && day !== "" && year !== "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }

  /* SAVING DATA */
  async function crearDetalleContratista() {
    if (!editMode) {
      const newDetalle = {
        dia: parseInt(day),
        mes: parseInt(month),
        ano: parseInt(year),
        contratista: contratista,
        centroCosto: centroCosto,
        unidad: unidad,
        labor: labor,
        jornada: parseFloat(jornada),
        valor: parseFloat(value),
        cantidad: parseFloat(quantity),
        total: total,
        trato: trato
      };
      resetData();
      await axios.post("/contratistas/detalle", newDetalle)
      .then((res)=> {
        if (res.status === 200) {
        fetchDetalles();
      }}
      ).catch((err) => {
        console.log(err);
      });
    }
    if (editMode) {
      saveEditedDetalleContratista();
      resetData();
      setEditMode(false);
    }
  }

  /* EDITING DATA */
  async function fillEditDetalleContratistaInfo(id) {
    setEditMode(true);
    setInfoMode(false);
    const { dia, mes, ano, contratista, centroCosto, unidad, labor, jornada, valor, cantidad, trato } = await detalles.find((d) => d._id === id);
    setCurrentId(id);
    setDay(dia);
    setMonth(mes);
    setYear(ano);
    setContratista(contratista);
    setCentroCosto(centroCosto);
    setUnidad(unidad);
    setLabor(labor);
    setJornada(jornada);
    setValue(valor);
    setQuantity(cantidad);
    setTrato(trato);
  }

  async function saveEditedDetalleContratista() {
    const newEditedDetalleContratista = {
      dia: parseInt(day),
      mes: parseInt(month),
      ano: parseInt(year),
      contratista: contratista,
      centroCosto: centroCosto,
      unidad: unidad,
      labor: labor,
      jornada: parseFloat(jornada),
      valor: parseFloat(value),
      cantidad: parseFloat(quantity),
      total: total,
      trato: trato,
    };
    await axios.patch(`/contratistas/detalle/${currentId}/edit`, newEditedDetalleContratista)
    .then((res)=> {
      if (res.status === 200) {
      fetchDetalles();
    }}
    ).catch((err) => {
      console.log(err);
    });
  }

  /* DISPLAYING DATA */
  function infoDetalleContratista(id) {
    setEditMode(false);
    setInfoMode(true);
    setDataLocked(true);
    const { dia, mes, ano, contratista, centroCosto, unidad, labor, jornada, valor, cantidad } = detalles.find((d) => d._id === id);
    setDay(dia);
    setMonth(mes);
    setYear(ano);
    setContratista(contratista);
    setCentroCosto(centroCosto);
    setUnidad(unidad);
    setLabor(labor);
    setJornada(jornada);
    setValue(valor);
    setQuantity(cantidad);
    setTrato(trato);
  }

  /* DELETING DATA */
  async function deleteDetalleContratista(id) {
    let answer = window.confirm("Eliminar Detalle Contratista?");
    if (answer) {
      await axios(`/contratistas/detalle/${id}/delete`)
      .then((res)=> {
        if (res.status === 200) {
        fetchDetalles();
      }}
      ).catch((err) => {
        console.log(err);
      })
    }
  }
  return (
    <Box>
      <Grid item component={Card}>
        <FormControl sx={{ p: "1%", gap: 1, pt: 1 }}>
          <Box display={"flex"} sx={{ flexDirection: "row", gap: 1 }}>
            <TextField required label="Mes" sx={{ width: 1 / 18 }} value={month} onChange={(e) => setMonth(e.target.value)} inputProps={{ readOnly: dataLocked || infoMode }} />
            <TextField required label="Año" sx={{ width: 1 / 18 }} value={year} onChange={(e) => setYear(e.target.value)} inputProps={{ readOnly: dataLocked || infoMode }} />
            <Autocomplete
              sx={{ width: 5 / 18 }}
              onChange={(e, nv) => setContratista(nv)}
              options={contratistas.filter(c => c.activo === true)}
              getOptionLabel={(c) => (`${c.empresa} - ${c.nombre}` ? `${c.empresa} - ${c.nombre}` : "")}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={contratista}
              renderInput={(params) => <TextField {...params} label="Contratista" required />}
              readOnly={dataLocked || infoMode}
            />
            <Autocomplete
              sx={{ width: 5 / 18 }}
              onChange={(e, nv) => setCentroCosto(nv)}
              options={centrosCosto.sort((a,b) => a.codigo - b.codigo)}
              getOptionLabel={(cc) => (cc.codigo ? cc.codigo + " - " + cc.nombre : "")}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={centroCosto}
              renderInput={(params) => <TextField {...params} label="Centro Costo" required />}
              readOnly={dataLocked || infoMode}
            />
            <Autocomplete
              sx={{ width: 5 / 18 }}
              onChange={(e, nv) => setLabor(nv)}
              options={labores.sort((a,b) => a.codigo - b.codigo)}
              getOptionLabel={(l) => (l.codigo ? l.codigo + " - " + l.nombre : "")}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={labor}
              renderInput={(params) => <TextField {...params} label="Labor" required />}
              readOnly={dataLocked || infoMode}
            />
            <Button onClick={handleDataLock}>{dataLocked ? <Lock fontSize="large" /> : <LockOpen fontSize="large" />}</Button>
          </Box>
          <Box display={"flex"} sx={{ flexDirection: "row", gap: 1 }}>
            <TextField required label="Dia" sx={{ width: 1 / 18 }} value={day} onChange={(e) => setDay(e.target.value)} inputProps={{ readOnly: infoMode }} />
            <Stack my="auto">
              <Checkbox size="small" value={trato} onClick={()=> setTrato(!trato)} sx={{p: 0}} checked={trato}/>
              <Typography sx={{fontSize: 13}}>Trato</Typography>
            </Stack>
            <TextField required label="Cantidad" sx={{ width: 2 / 18 }} value={quantity} onChange={(e) => setQuantity(e.target.value)} inputProps={{ readOnly: infoMode }} />
            <Autocomplete
              required
              sx={{ width: 2 / 18 }}
              value={unidad}
              isOptionEqualToValue={(option, value) => option === value}
              options={unidades}
              onChange={(e, nv) => setUnidad(nv)}
              renderInput={(params) => <TextField {...params} label="Un." />}
              readOnly={infoMode}
            />
            <TextField required label="Jornadas" sx={{ width: 1 / 18 }} value={jornada} onChange={(e) => setJornada(e.target.value)} inputProps={{ readOnly: infoMode }} />
            <TextField required label="Valor" sx={{ width: 2 / 18 }} value={value} onChange={(e) => setValue(e.target.value)} inputProps={{ readOnly: infoMode }} />
            <TextField required label="Total" sx={{ width: 3 / 18 }}
             value={trato ? (quantity * value !== 0 ? Math.round(quantity * value) : "") : (jornada * value !== 0 ? Math.round(jornada * value) : "")}
              onChange={calculateTotal()} inputProps={{ readOnly: true }} />
            <Button onClick={crearDetalleContratista} disabled={!isFormValid} sx={{ width: 1 / 18, display: { xs: infoMode ? "none" : "flex" } }} size="large" type="button" variant="contained">
              <Save fontSize="large" />
            </Button>
            <Button onClick={closeInfoMode} sx={{ width: 1 / 18, display: { xs: infoMode ? "flex" : "none" } }} size="large" type="button" variant="contained">
              <Close fontSize="large" />
            </Button>
            <Button onClick={handleOpenOpcionImpresion} sx={{ width: 1 / 18 }} size="large" type="button" variant="contained">
              <Print fontSize="large" />
            </Button>
            <Dialog open={openOpcionImpresion} onClose={()=>closeOpcionImpresion()}>
                <DialogTitle align="center">Imprimir</DialogTitle>
                <List sx={{ pt: 0 }}>
                  <ListItem disableGutters>
                    <ListItemButton onClick={()=>closeOpcionImpresion(1)}>
                      <ListItemText>Resumen</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={()=>closeOpcionImpresion(2)}>
                      <ListItemText>Detalle</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={()=>closeOpcionImpresion(3)}>
                      <ListItemText>OC</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Dialog>
            <Button onClick={resetData} sx={{ width: 1 / 18 }} size="large" type="button" variant="contained">
              <Backspace fontSize="large" />
            </Button>
          </Box>
        </FormControl>
      </Grid>
      <Grid component={Card} mt={"0.5%"}>
        <Table size="small">
          <TableHead>
            <TableRow key={"headers"}>
              <TableCell align="center">
                <b>Dia</b>
              </TableCell>
              <TableCell>
                <b>Centro Costo</b>
              </TableCell>
              <TableCell>
                <b>Labor</b>
              </TableCell>
              <TableCell align="center">
                <b>Cantidad</b>
              </TableCell>
              <TableCell align="center">
                <b>Uni.</b>
              </TableCell>
              <TableCell align="center">
                <b>Valor</b>
              </TableCell>
              <TableCell align="center">
                <b>Total</b>
              </TableCell>
              <TableCell align="center">
                <b>Mes/Año</b>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {detalles
              .sort((a,b) => sortDetalles(a,b))
              .filter(filterDetalleContratista)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((d) => (
                <TableRow key={d._id}>
                  <TableCell align="center" width={1 / 18}>
                    {d.dia}
                  </TableCell>
                  <TableCell>{d.centroCosto.codigo + " - " + d.centroCosto.nombre}</TableCell>
                  <TableCell>{d.labor.codigo + " - " + d.labor.nombre}</TableCell>
                  <TableCell align="center">{d.cantidad}</TableCell>
                  <TableCell align="center">{d.unidad}</TableCell>
                  <TableCell align="center">{d.valor}</TableCell>
                  <TableCell align="center">{d.total}</TableCell>
                  <TableCell align="center">{d.mes + "/" + d.ano}</TableCell>
                  <Options id={d._id} info={infoDetalleContratista} edit={fillEditDetalleContratistaInfo} delete={deleteDetalleContratista} />
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        component="div"
        rowsPerPageOptions={[25]}
        count={detalles.filter((d) => (contratista !== null ? d.contratista._id === contratista._id : false)).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Box>
  );
}

import { React } from "react";
import { Card, FormControl, TableBody, Grid, Button, TextField, Table, TableCell, TableContainer, TableHead, TableRow, FormControlLabel, Switch } from "@mui/material";
import Options from "../components/Options";
import { useState, useEffect } from "react";
import axios from "axios";

export default function CrearContratista() {
  const [currentId, setCurrentId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [infoMode, setInfoMode] = useState(false);
  const [contratistas, setContratistas] = useState([]);
  const [nombre, setNombre] = useState("");
  const [rut, setRut] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [rutEmpresa, setRutEmpresa] = useState("");
  const [domicilio, setDomicilio] = useState("");
  const [correo, setCorreo] = useState("");
  const [activo, setActivo] = useState(true);

  const fetchContratista = async () => {
    return await axios.get("/contratistas").then((res) => setContratistas(res.data))
  }

  useEffect(() => {
    fetchContratista();
  }, []);

  function crearContratista() {
    if (!editMode) {
      const newContratista = { nombre: nombre, rut: rut, empresa: empresa, rutEmpresa: rutEmpresa, domicilio: domicilio, correo: correo, activo: activo };
      axios.post("/contratistas", newContratista)
      .then((res)=> {
        if (res.status === 200) {
        fetchContratista();
      }}
      ).catch((err) => {
        console.log(err);
      });
      resetData();
    }
    if (editMode) {
      saveEditedContratista();
      resetData();
      setEditMode(false);
    }
  }

  function resetData() {
    setNombre("");
    setRut("");
    setEmpresa("");
    setRutEmpresa("");
    setDomicilio("");
    setCorreo("");
		setActivo(true);
  }

  async function fillEditContratistaInfo(id) {
    setEditMode(true);
    setInfoMode(false);
    const { nombre, rut, empresa, rutEmpresa, domicilio, correo, activo } = await contratistas.find((c) => c._id === id);
    setCurrentId(id);
    setNombre(nombre);
    setRut(rut);
    setEmpresa(empresa);
    setRutEmpresa(rutEmpresa);
    setDomicilio(domicilio);
    setCorreo(correo);
		setActivo(activo);
  }

  async function infoContratista(id) {
    setEditMode(false);
    setInfoMode(true);
    const { nombre, rut, empresa, rutEmpresa, domicilio, correo, activo } = await contratistas.find((c) => c._id === id);
    setNombre(nombre);
    setRut(rut);
    setEmpresa(empresa);
    setRutEmpresa(rutEmpresa);
    setDomicilio(domicilio);
    setCorreo(correo);
		setActivo(activo);
  }

  async function saveEditedContratista() {
    const newEditedContratista = { nombre: nombre, rut: rut, empresa: empresa, rutEmpresa: rutEmpresa, domicilio: domicilio, correo: correo, activo: activo };
    await axios.patch(`/contratistas/${currentId}/edit`, newEditedContratista)
    .then((res)=> {
      if (res.status === 200) {
      fetchContratista();
    }}
    ).catch((err) => {
      console.log(err);
    });
  }

  async function deleteContratista(id) {
    let answer = window.confirm("Eliminar Contratista?");
    if (answer) {
      await axios(`/contratistas/${id}/delete`)
      .then((res)=> {
        if (res.status === 200) {
        fetchContratista();
      }}
      ).catch((err) => {
        console.log(err);
      });
    }
  }

  function closeInfoMode() {
    setInfoMode(false);
    resetData();
  }

  return (
    <Grid container>
      <Grid item xs={3} component={Card} maxHeight={530}>
        <FormControl sx={{ gap: 2, width: "100%", p: "1%" }}>
          <TextField
            required
            id="nombreContratista"
            label="Nombre Contratista"
            variant="outlined"
            value={nombre}
            onChange={(e) => {
              setNombre(e.target.value);
            }}
            InputProps={{ readOnly: infoMode ? true : false }}
          />
          <TextField
            required
            id="rutContratista"
            label="Rut Contratista"
            variant="outlined"
            value={rut}
            onChange={(e) => {
              setRut(e.target.value);
            }}
            InputProps={{ readOnly: infoMode ? true : false }}
          />
          <TextField
            required
            id="empresaContratista"
            label="Empresa Contratista"
            variant="outlined"
            value={empresa}
            onChange={(e) => {
              setEmpresa(e.target.value);
            }}
            InputProps={{ readOnly: infoMode ? true : false }}
          />
          <TextField
            required
            id="rutEmpresaContratista"
            label="Rut Empresa Contratista"
            variant="outlined"
            value={rutEmpresa}
            onChange={(e) => {
              setRutEmpresa(e.target.value);
            }}
            InputProps={{ readOnly: infoMode ? true : false }}
          />
          <TextField
            required
            id="domicilioContratista"
            label="Domicilio Contratista"
            variant="outlined"
            value={domicilio}
            onChange={(e) => {
              setDomicilio(e.target.value);
            }}
            InputProps={{ readOnly: infoMode ? true : false }}
          />
          <TextField
            required
            id="correoContratista"
            label="Correo"
            variant="outlined"
            value={correo}
            onChange={(e) => {
              setCorreo(e.target.value);
            }}
            InputProps={{ readOnly: infoMode ? true : false }}
          />
					<FormControlLabel sx={{pl: 1}} control={<Switch required checked={activo} onChange={(e) => setActivo(e.target.checked)}/>} label="Activo" />
          <Button sx={{ height: 1 / 5, display: { xs: infoMode ? "none" : "block" } }} variant="contained" type="button" onClick={crearContratista}>
            {editMode ? "Guardar" : "Crear"}
          </Button>
          <Button sx={{ height: 1 / 5, display: { xs: infoMode ? "block" : "none" } }} variant="contained" onClick={closeInfoMode}>
            Cerrar
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={9} paddingLeft="1%" paddingRight="2%">
        <TableContainer component={Card}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Contratista</b>
                </TableCell>
                <TableCell>
                  <b>Empresa</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contratistas.map((c) => (
                <TableRow key={c._id} sx={{backgroundColor: c.activo ? "inherit" : "#D3D3D3"}}>
                  <TableCell width={"35%"}>{c.nombre}</TableCell>
                  <TableCell width={"53%"}>{c.empresa}</TableCell>
                  <Options id={c._id} info={infoContratista} edit={fillEditContratistaInfo} delete={deleteContratista} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
